<template>
  <section class="section-block">
    <SectionTitle title="付款方式" btn="新增" @edit="onCreate" />
    <div>
      <BaseTable :data="displayData">
        <template #empty> 尚未有付款方式紀錄，請先新增 </template>
        <BaseElTableColumn label="名稱" prop="name" width="200" align="center" />
        <BaseElTableColumn label="啟用" prop="displayEnable" align="center">
          <template slot-scope="scope">
            <Tag :type="scope.row.enable ? 'action' : 'danger'">
              {{ scope.row.displayEnable }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" align="center" width="200">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :deleteDisabled="disableDelete"
              @edit="onEditPayment(scope.row)"
              @delete="onDeletePayment(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </div>

    <EditPaymentModal
      v-if="modal.edit"
      :editType="modalType"
      :configData="payments"
      :paymentOptions="paymentOptions"
      :omitPayments="omitPayments"
      :select-payment="selectPayment"
      :updateApi="updateApi"
      :createApi="createApi"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      :loading="loading.delete"
      width="40%"
      @close="modal.delete = false"
      @delete="deletePayment"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, computed, ref } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EditPaymentModal from './EditPaymentModal.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { map, get, omit, find, filter } from 'lodash'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { paymentOptionsConfig } from '@/config/payment'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'PaymentSettingBlock',
  components: { SectionTitle, EditPaymentModal, DeleteDialog, Tag },
  props: {
    deleteLast: { type: Boolean, default: true },
    payments: { type: Array, default: () => [] },
    updateApi: { type: Function, default: () => {} },
    createApi: { type: Function, default: () => {} },
    deleteApi: { type: Function, default: () => {} },
    omitPayments: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const { shopId } = useShop()
    const modalType = ref('create')
    const selectPayment = ref({})
    const loading = reactive({ delete: false })
    const modal = reactive({
      edit: false,
      delete: false,
    })

    const disableDelete = computed(() => {
      return !props.deleteLast && props.payments.length === 1
    })

    const paymentOptions = computed(() => {
      return filter(
        map(omit(paymentOptionsConfig, props.omitPayments), (item) => ({
          value: item.value,
          label: item.name,
        })),
        (option) => !find(props.payments, { type: option.value }),
      )
    })

    const displayData = computed(() => {
      return map(props.payments, (item) => {
        return {
          name: get(paymentOptionsConfig, `${item.type}.name`, '-'),
          enable: item.enable,
          displayEnable: item.enable ? '是' : '否',
          type: item.type,
          id: item.id,
        }
      })
    })

    const onCreate = () => {
      selectPayment.value = {}
      modal.edit = true
      modalType.value = 'create'
    }

    const onEditPayment = (payment) => {
      modal.edit = true
      modalType.value = 'edit'
      selectPayment.value = payment
    }
    const onDeletePayment = (payment) => {
      modal.delete = true
      selectPayment.value = payment
    }

    const deletePayment = async () => {
      if (loading.delete) return
      loading.delete = true
      const [, errMsg] = await props.deleteApi({
        shopId: shopId.value,
        id: selectPayment.value.id,
      })
      loading.delete = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success(notifyMessage.deleteSuccess)
      emit('refresh')
      modal.delete = false
    }

    return {
      onCreate,
      modal,
      paymentOptions,
      displayData,
      modalType,
      onEditPayment,
      onDeletePayment,
      selectPayment,
      deletePayment,
      loading,
      disableDelete,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
