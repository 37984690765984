<template>
  <el-dialog
    :title="displayData.title"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="種類" prop="paymentType">
        <BaseElSelect v-model="formData.paymentType" :disabled="disableForm" placeholder="請選擇">
          <BaseElSelectOption
            v-for="option in displayData.paymentOptions"
            :key="option.value"
            :label="option.label || option.name"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData[formData.paymentType]" label="是否啟用">
        <BaseElSwitch
          v-model="formData[formData.paymentType].enable"
          active-text="是"
          inactive-text="否"
        />
      </BaseElFormItem>
    </BaseElForm>
    <BaseElForm
      ref="configFormRef"
      :disabled="disableForm"
      :rules="formRules"
      :model="formData"
      label-position="top"
    >
      <!-- LINE Pay -->
      <LinePayFrom v-if="formData.paymentType === 'linepay'" :formData.sync="formData.linepay" />
      <!-- 街口 -->
      <JkosPayForm v-if="formData.paymentType === 'jkos'" :formData.sync="formData.jkos" />
      <!-- 藍新 -->
      <NewebPayForm v-if="formData.paymentType === 'newebpay'" :formData.sync="formData.newebpay" />
    </BaseElForm>

    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="onSubmit"> 確認 </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import LinePayFrom from '@/components/Payment/LinePayForm.vue'
import JkosPayForm from '@/components/Payment/JkosPayForm.vue'
import NewebPayForm from '@/components/Payment/NewebPayForm.vue'
import { isEmpty, omit, map, get, find, forEach, set, filter } from 'lodash'
import { useBaseForm } from '@/use/useForm'
import { computed, onBeforeMount, ref } from 'vue'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { paymentOptionsConfig, paymentConfigFields } from '@/config/payment'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'

export default {
  name: 'EditPaymentModal',
  components: { LinePayFrom, JkosPayForm, NewebPayForm },
  props: {
    editType: { type: String, default: 'create' },
    paymentOptions: { type: Array, default: () => [] },
    configData: { type: Array, default: () => [] },
    selectPayment: { type: Object, default: () => ({}) },
    updateApi: { type: Function, default: () => {} },
    createApi: { type: Function, default: () => {} },
    omitPayments: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const configFormRef = ref(null)
    const formRules = computed(() => {
      return {
        paymentType: [noEmptyRules()],
        linepay: {
          linePayChannelId: [noEmptyRules()],
          linePayChannelSecretKey: [noEmptyRules()],
        },
        jkos: {
          jkosStoreId: [noEmptyRules()],
          jkosApiKey: [noEmptyRules()],
          jkosSercertKey: [noEmptyRules()],
        },
        newebpay: {
          newebpayMerchantId: [noEmptyRules()],
          newebpayHashKey: [noEmptyRules()],
          newebpayHashIV: [noEmptyRules()],
        },
      }
    })
    const disableForm = computed(() => props.editType === 'edit' || loading.value)
    const { shopId } = useShop()

    const displayData = computed(() => {
      return {
        title: props.editType === 'create' ? '新增付款方式' : '編輯付款方式',
        paymentOptions: props.selectPayment.type
          ? omit(paymentOptionsConfig, props.omitPayments)
          : filter(props.paymentOptions, (i) => !props.omitPayments.includes(i.value)),
      }
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      if (!(await formUtils.checkForm(configFormRef.value))) {
        loading.value = false
        return
      }
      if (props.editType === 'create') {
        const [, errMsg] = await props.createApi({
          shopId: shopId.value,
          enable: formData[formData.paymentType].enable,
          type: formData.paymentType,
          config: omit(formData[formData.paymentType], ['enable']),
        })
        loading.value = false
        if (errMsg) {
          window.$message.error(errMsg)
          return
        }
        window.$message.success(notifyMessage.createSuccess)
      } else {
        const paymentId = get(find(props.configData, { type: formData.paymentType }), 'id')
        const [, errMsg] = await props.updateApi({
          shopId: shopId.value,
          enable: formData[formData.paymentType].enable,
          id: paymentId,
        })
        loading.value = false
        if (errMsg) {
          window.$message.error(errMsg)
          return
        }
        window.$message.success(notifyMessage.updateSuccess)
      }
      emit('refresh')
      emit('close')
    }

    const syncConfigData = (payment) => {
      const paymentType = payment.type
      const paymentConfigData = payment.config || {}
      set(formData[paymentType], 'enable', payment.enable)
      if (!paymentConfigData || isEmpty(paymentConfigData)) return
      forEach(paymentConfigData, (value, key) => {
        set(formData[paymentType], key, value)
      })
    }

    const init = () => {
      const data = {}
      map(paymentOptionsConfig, (option) => {
        const configFields = get(paymentConfigFields, option.value, [])
        data[option.value] = {
          enable: false,
        }
        forEach(configFields, (field) => {
          set(data[option.value], field, null)
        })
      })

      initFormData({
        paymentType: null,
        ...data,
      })

      if (props.editType === 'create') return

      for (const payment of props.configData) {
        syncConfigData(payment)
      }

      if (props.selectPayment) {
        formData.paymentType = props.selectPayment.type
      }
    }

    onBeforeMount(() => {
      init()
    })

    return {
      configFormRef,
      formRef,
      formData,
      initFormData,
      loading,
      formRules,
      onSubmit,
      disableForm,
      displayData,
    }
  },
}
</script>

<style scoped lang="scss"></style>
